/**
 * @author: Tejas Upmanyu (@tejasupmanyu)
 * TaskList Component - Renders list of task cards of all the tasks entered in timesheet.
 */
import * as React from 'react';
import './styles.scss';
import { IEntry } from '../NewEntrySheet';
import crossicon from '../../assets/cross-icon.svg';

interface ITaskListProps {
    entries: IEntry[];
    onDelete: (id: string) => void;
}
interface ITaskCardProps {
    entry: IEntry;
    onDelete: (id: string) => void;
}

export const TaskList: React.FC<ITaskListProps> = (props: ITaskListProps) => {
    const { entries } = props;
    return (
        <div className="task-list">
            {entries.map((entry: IEntry, index: number) => (
                <TaskCard key={index} entry={entry} onDelete={props.onDelete} />
            ))}
        </div>
    );
};

const TaskCard: React.FC<ITaskCardProps> = (props: ITaskCardProps) => {
    const { id, task, hours, minutes, remarks } = props.entry;
    const onDelete = () => {
        props.onDelete(id);
    };
    return (
        <div className="task-card">
            <div className="task-head">
                <div className="task-title">{task}</div>
                <div className="task-time">{`${hours}h ${minutes}m`}</div>
                <button className="delete-task" onClick={onDelete}>
                    <img src={crossicon} alt="delete" />
                </button>
            </div>
            <div className="remarks">{remarks}</div>
        </div>
    );
};
