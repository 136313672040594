/**
 * @author: Tejas Upmanyu (@tejasupmanyu)
 * App Component
 */
import React from 'react';
import './App.scss';
import addIcon from './assets/plus-icon.svg';
import { NewEntrySheet, IEntry } from './components/NewEntrySheet';
import { TaskList } from './components/TaskList';
import { storageKey, ADD, SUBTRACT, HOUR, RED, GREEN } from './constants/constants';

const App: React.FC = () => {
    const [isEntrySheetOpen, setIsEntrySheetOpen] = React.useState(false);
    const [update, setUpdate] = React.useState(false);
    const [time, setTime] = React.useState(0);

    const openEntrySheet = () => {
        setIsEntrySheetOpen(true);
    };

    let progressbarStyle = {
        width: (time * 100) / GREEN + '%',
        backgroundColor: time < RED ? 'red' : time >= RED && time < GREEN ? 'orange' : 'green',
    };

    const closeEntrySheet = () => {
        setIsEntrySheetOpen(false);
    };

    const onAddEntry = (entry: IEntry) => {
        const existingTasksString = window.localStorage.getItem(storageKey);
        updateTime(entry, ADD);

        if (existingTasksString) {
            const existingTasks = JSON.parse(existingTasksString);
            const newTasks = [...existingTasks, entry];
            window.localStorage.setItem(storageKey, JSON.stringify(newTasks));
        } else {
            window.localStorage.setItem(storageKey, JSON.stringify([entry]));
        }
        closeEntrySheet();
    };

    const getMinutes = (entry: IEntry) => {
        const { hours, minutes } = entry;
        const value: number = parseInt(hours) * HOUR + parseInt(minutes);
        return value;
    };

    const updateTime = (entry: IEntry, operation: string) => {
        const value: number = getMinutes(entry);
        operation === ADD ? setTime(time + value) : setTime(time - value);
    };

    const onDeleteEntry = (id: string) => {
        const existingTasksString = window.localStorage.getItem(storageKey);
        if (existingTasksString) {
            const existingTasks = JSON.parse(existingTasksString);
            const newTasks = existingTasks.filter((task: IEntry) => {
                if (task.id === id) {
                    updateTime(task, SUBTRACT);
                }
                return task.id !== id;
            });
            window.localStorage.setItem(storageKey, JSON.stringify(newTasks));
            setUpdate(!update);
        }
    };

    const getTaskEntries = () => {
        const entriesString = window.localStorage.getItem(storageKey);
        const entries = entriesString ? JSON.parse(entriesString) : [];
        let curTime = 0;

        entries.forEach((entry: IEntry) => {
            curTime += getMinutes(entry);
        });
        if (time < curTime) {
            // setTime need only first time when tasklist fetched from localstorage
            setTime(curTime);
        }
        return entries;
    };

    const entries = getTaskEntries();

    return (
        <div className="app-container">
            <h1>Timesheet</h1>

            <div className="progress-bar">
                <div className="progress" style={progressbarStyle}></div>
            </div>
            {entries.length > 0 ? (
                <TaskList entries={entries} onDelete={onDeleteEntry} />
            ) : (
                <p className="empty-text">No entries yet. Add a new entry by clicking the + button.</p>
            )}
            <button className="floating-add-entry-btn" onClick={openEntrySheet}>
                <img className="add-icon" src={addIcon} alt="add entry" />
            </button>
            {isEntrySheetOpen && <NewEntrySheet onClose={closeEntrySheet} onAdd={onAddEntry} />}
        </div>
    );
};

export default App;
